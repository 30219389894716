@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #C8EAD7;
  height: 100vh;
}

#root {
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lora', serif;
  font-weight: 700;
}

h1 {
  margin: 50px 0 25px 0;
}

a {
  color: #995205 !important;
}

a:hover {
  border-bottom: 2px solid;
  text-decoration: none;
}

em {
  font-style: italic;
}

.underline {
  border-bottom: 2px solid black;
}

.red {
  color: #c00000 !important
}

li {
  list-style-type: disc;
  margin-left: 50px;
}

.nav-link {
  padding: 0 !important;
  margin: 8px !important;
}

.nav-link:hover {
  padding: 0 !important;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 131px;
}

@media screen and (max-width: 1024px){
  body {
    font-size: 14px;
  }

  h1 {
    margin: 25px 0;
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  .page {
    top: 128px;
  }
}

@media screen and (max-width: 991px){
  .nav-link:hover {
    border-bottom: 0;
  }
}

@media screen and (max-width: 479px) {
  li {
    margin-left: 25px;
  }

  .page {
    top: 106px;
  }
}

@media screen and (max-width: 320px){
  .page {
    top: 95px;
  }
}